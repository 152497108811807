.p-reOtp {
  padding-bottom: rem(24);
  @include bg-custom();

  .description {
    margin-top: rem(20);
  }

  .form {
    .item {
      display: flex;
      justify-content: center;
      margin-top: rem(16);

      &:first-of-type {
        margin-top: rem(24);
      }

      .label,
      .value {
        padding-left: rem(12);
        padding-right: rem(12);
      }

      .label {
        flex: 0 0 rem(100);
        max-width: rem(100);
      }

      .value {
        max-width: rem(200);
        width: 100%;

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
        }
      }

      .checked {
        padding-left: rem(18);
        padding-right: rem(18);
      }
    }

    .submit {
      margin-left: auto;
      margin-right: auto;
      margin-top: rem(40);
      max-width: rem(171);

      button[type='submit'] {
        display: none;
      }

      &_verify {
        button[type='button'] {
          display: none;
        }

        button[type='submit'] {
          display: block;
        }
      }
    }
  }

  .checked {
    .a-textbutton {
      text-align: left;
    }
  }
  
  .submit {
    .a-button {
      height: rem(58);
    }
  }

  .bottom {
    margin-top: rem(24);
    padding-left: rem(16);
    padding-right: rem(16);
    @include font-base (10, 16);

    .abbott {
      width: rem(75);
    }
  }
}

.terms_popup {
  &_content {
    margin-top: rem(24);
  }

  &_list {
    margin-left: rem(28);

    li {
      margin-top: rem(27);

      &::marker {
        color: $mediumSeaGreen;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &_accept {
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(32);
    max-width: rem(198);

    .a-button {
      height: rem(58);
    }
  }
}


.otp-popup {
  position: relative;

  &_loading {
    align-items: center;
    background-color: $white-overlay08;
    bottom: rem(-20);
    display: flex;
    height: calc(100% + 40px);
    justify-content: center;
    left: rem(-16);
    position: absolute;
    right: rem(-16);
    top: rem(-20);
    width: calc(100% + 32px);
  }

  .title,
  .content,
  .input {
    margin-bottom: rem(16);
  }

  .input {
    @include adjust-flex(space-between, center);

    input {
      background-color: #f0f0f1;
      border-color: $yale-blue;
      border-radius: rem(4);
      color: $black;
      font-family: $iCielBrandonText;
      height: 100%;
      max-height: rem(38);
      max-width: rem(38);
      outline: none;
      text-align: center;
      width: 100%;
      @include font-base(32, 26);
    }
  }

  .error {
    color: red;
    margin: auto auto rem(16);
    width: rem(250);
  }

  .resend-otp {
    margin-bottom: rem(32);
    text-align: center;
  }

  .submit {
    margin: auto;
    width: rem(165);

    .a-button {
      height: rem(48);
    }
  }
}

.received_popup {
  .title {
    margin-top: rem(12);
  }

  .description {
    margin-top: rem(16);

    .link {
      color: $blizzard-blue;
      text-decoration: underline;
    }
  }

  .button {
    margin-top: rem(24);
    text-align: center;

    .a-button {
      height: rem(58);
      max-width: rem(171);
    }
  }
}
